<template>
	<section class="container">
		<div class="row justify-content-end">
			<div class="col-auto">
				<BaseActionButton type="button" v-on:on-click="setEditable" v-if="$can('update', 'Employee', 'CompanyAdvisors')">
					<font-awesome-icon :icon="['far', 'edit']" class="icon" />
				</BaseActionButton>
			</div>
		</div>
		<b-form @submit.prevent="onSubmit">
			<div class="row justify-content-center">
				<div class="col-lg-6 col-12">
					<label
						><b>{{ $t('global.primaryAdvisor') }}</b></label
					>
					<company-advisor v-model="employeePolicy.companyAdvisors.primaryAdvisor" :disabled="disabled" />
					<label
						><b>{{ $t('global.secondaryAdvisor') }}</b></label
					>
					<company-advisor v-model="employeePolicy.companyAdvisors.secondaryAdvisor" :disabled="disabled" />
				</div>
			</div>
			<div class="row mb-5">
				<div class="col-12">
					<BaseActionButton class="btn-update" v-bind:clicked="clicked" v-bind:disabled="clicked" v-show="!disabled" type="submit" id="signupButton">
						{{ $t('global.update') }}
					</BaseActionButton>
				</div>
			</div>
		</b-form>
	</section>
</template>

<script>
import CompanyAdvisor from '@/modules/global/components/AdvisorDropdown.vue'

export default {
	name: 'CompanyEmployeeInfo',
	components: {
		CompanyAdvisor,
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	data() {
		return {
			clicked: false,
			disabled: true,
		}
	},
	methods: {
		setEditable() {
			this.disabled = !this.disabled
		},
		async onSubmit() {
			console.log('UPDATING USER AND POLICY')
			this.clicked = true
			var policyId = this.employeePolicy.id
			await this.$store.dispatch('employeeVuex/updateEmployeePolicy', {
				id: policyId,
				data: this.employeePolicy,
				companyId: this.companyId,
				employeeId: this.employeeId,
			})
			this.toast('Success', 'En medarbejder nu opdateret', true)
			this.clicked = false
		},
	},
	computed: {
		companyId() {
			return this.$route.params.companyId
		},
		employeeId() {
			return this.$route.params.employeeId
		},
		employeeInfo() {
			const employee = this.$store.getters['employeeVuex/user']
			return employee
		},
		employeePolicy() {
			return this.$store.getters['employeeVuex/employeePolicy']
		},
	},
}
</script>

<style></style>
